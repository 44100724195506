import React from 'react';
import { Grid, Typography } from '@mui/material';

const KeyValueGrid = ({ data }) => {
	return (
		<Grid container spacing={1}>
			{Object.entries(data).map(([key, value]) => (
				<React.Fragment key={key}>
					<Grid item xs={4}>
						<Typography variant='subtitle1' fontWeight='bold'>
							{key}:
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
							{typeof value === 'object' && value !== null
								? JSON.stringify(value, null, 4)
								: value}
						</Typography>
					</Grid>
				</React.Fragment>
			))}
		</Grid>
	);
};

export default KeyValueGrid;