import React, { useState, useEffect } from "react";
import {
	Box,
	Select,
	MenuItem,
	Typography,
	Grid,
	IconButton,
	Tooltip,
} from "@mui/material";
import useHttpService from "../../customHooks/useHttpService";
import { Refresh } from "@mui/icons-material";
import { useUserContext } from "../../contexts/userContext.js";

const apiGetDashboardPath = "/api/scalar/grafana/Dashboard";
const apiUrlGetDashboard = new URL(
	apiGetDashboardPath,
	process.env.NODE_ENV === "production"
		? window.location.origin + process.env.REACT_APP_PROD_API_BASE_PATH
		: process.env.REACT_APP_DEV_API_BASE_URL +
		  process.env.REACT_APP_DEV_API_BASE_PATH
);

const DataView = () => {
	const httpService = useHttpService();
	const userContext = useUserContext();
	const user = userContext.getUser();

	const [dashboards, setDashboards] = useState([]);
	const [selectedDashboard, setSelectedDashboard] = useState(null);
	const [iframeKey, setIframeKey] = useState(0);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [fetchRequired, setFetchRequired] = useState(true);

	const fetchDashboards = () => {
		console.log("Fetching dashboards from:", apiUrlGetDashboard.href);

		setIsLoading(true);
		setIsError(false);

		httpService.getAPI(
			apiUrlGetDashboard,
			dashboards,
			setDashboards,
			() => {},
			setIsLoading,
			setIsRefetching,
			setIsError
		);
	};

	useEffect(() => {
		fetchDashboards();
	}, [fetchRequired]);

	const handleDashboardChange = (event) => {
		setSelectedDashboard(event.target.value);
	};

	const handleRefresh = () => {
		setIframeKey((prevKey) => prevKey + 1);
	};

	return (
		<Box sx={{ p: 2 }}>
			<Typography variant="h6" gutterBottom>
				Grafana Dashboard Viewer
			</Typography>

			<Grid container spacing={2} alignItems="center">
				<Grid item xs={12} md={4}>
					<Select
						fullWidth
						value={selectedDashboard || ""}
						onChange={handleDashboardChange}
						displayEmpty
					>
						{dashboards.map((dashboard) => (
							<MenuItem key={dashboard.id} value={dashboard.url}>
								{dashboard.name}
							</MenuItem>
						))}
					</Select>
				</Grid>

				{/* Refresh Button */}
				<Grid item>
					<Tooltip arrow title="Refresh">
						<IconButton onClick={handleRefresh}>
							<Refresh />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>

			{/* Display loading or error messages */}
			{isLoading && (
				<Typography color="primary">Loading dashboards...</Typography>
			)}
			{isError && (
				<Typography color="error">Error loading dashboards</Typography>
			)}

			{/* Display selected dashboard */}
			{selectedDashboard && !isError && (
				<Box
					sx={{
						mt: 2,
						border: "1px solid #ccc",
						borderRadius: 2,
						overflow: "hidden",
					}}
				>
					<iframe
						key={iframeKey}
						src={selectedDashboard}
						width="100%"
						height="700px"
						title="Grafana Dashboard"
						style={{ border: "none" }}
					></iframe>
				</Box>
			)}
		</Box>
	);
};

export default DataView;
