import React, { useState, useEffect } from "react";
import {
	Box,
	Tabs,
	Tab,
	Select,
	MenuItem,
	Button,
	Typography,
	Grid,
} from "@mui/material";
import Editor from "@monaco-editor/react";
import useHttpService from "../../customHooks/useHttpService";

function ApiTester() {
	const { getRelayEndpoints, relayAPI } = useHttpService();

	const [apiEndpoints, setApiEndpoints] = useState([]);
	const [selectedApi, setSelectedApi] = useState(null);
	const [requestTab, setRequestTab] = useState(0);
	const [responseTab, setResponseTab] = useState(0);
	const [requestHeaders, setRequestHeaders] = useState(`{}`);
	const [requestBody, setRequestBody] = useState(`{}`);
	const [response, setResponse] = useState({ headers: `{}`, body: `{}` });
	const [statusCode, setStatusCode] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		getRelayEndpoints(setApiEndpoints, setIsLoading, setIsError);
	}, []);

	const jsonToQueryString = (json) => {
		const params = new URLSearchParams();
		Object.keys(json).forEach((key) => {
			if (Array.isArray(json[key])) {
				json[key].forEach((value) => params.append(key, value));
			} else {
				params.append(key, json[key]);
			}
		});
		return params.toString();
	};

	const handleSelectApi = (event) => {
		const api = apiEndpoints.find((api) => api.id === event.target.value);
		setSelectedApi(api);

		if (!api) return;

		const headers = api.headers ? JSON.stringify(api.headers, null, 2) : `{}`;

		let body = "{}";

		if (api.request) {
			if (api.content_type === "application/json") {
				try {
					body = JSON.stringify(JSON.parse(api.request), null, 2);
				} catch (error) {
					body = api.request;
				}
			} else if (api.content_type === "text/xml") {
				body = api.request;
			} else {
				body = api.request;
			}
		}

		setRequestHeaders(headers);
		setRequestBody(body);
		setResponse({ headers: `{}`, body: `{}` });
		setStatusCode(null);
	};

	const handleSendRequest = () => {
		if (!selectedApi) return;

		let url = selectedApi.url;
		let data = requestBody;

		if (selectedApi.method === "GET" && requestBody !== "{}") {
			try {
				const jsonBody = JSON.parse(requestBody);
				const queryString = jsonToQueryString(jsonBody);
				url += `?${queryString}`;
				data = null;
			} catch (error) {
				console.error("Invalid JSON for query string:", error);
			}
		} else if (selectedApi.content_type === "application/json") {
			try {
				data = JSON.stringify(JSON.parse(requestBody));
			} catch (error) {
				console.error("Invalid JSON input", error);
			}
		}

		relayAPI(
			selectedApi.method,
			url,
			{ headers: JSON.parse(requestHeaders), data },
			(response) => {
				setStatusCode(response?.status || "Unknown");

				setResponse({
					headers: JSON.stringify(
						{
							"Content-Type": selectedApi.content_type || "N/A",
							Status: response?.status || "N/A",
							Date: new Date().toUTCString(),
						},
						null,
						2
					),
					body: JSON.stringify(response, null, 2),
				});
			},
			(error) => {
				setStatusCode(error.response?.status || "Error");

				setResponse({
					headers: JSON.stringify(error.response?.headers || {}, null, 2),
					body: JSON.stringify(
						error.response?.data || { error: "Request failed" },
						null,
						2
					),
				});
			}
		);
	};

	return (
		<Box sx={{ p: 2 }}>
			<Typography variant="h6" gutterBottom>
				API Tester
			</Typography>

			<Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
				<Grid item xs={8}>
					<Select
						fullWidth
						value={selectedApi ? selectedApi.id : ""}
						onChange={handleSelectApi}
					>
						{isLoading ? (
							<MenuItem disabled>Loading...</MenuItem>
						) : isError ? (
							<MenuItem disabled>Error Loading APIs</MenuItem>
						) : (
							apiEndpoints.map((api) => (
								<MenuItem key={api.id} value={api.id}>
									{api.name} ({api.method})
								</MenuItem>
							))
						)}
					</Select>
				</Grid>
				<Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
					<Button
						variant="contained"
						color="secondary"
						onClick={handleSendRequest}
					>
						Send
					</Button>
					{/* Display Status Code */}
					{statusCode !== null && (
						<Typography
							variant="body1"
							sx={{
								ml: 2,
								fontWeight: "bold",
								color: statusCode >= 200 && statusCode < 300 ? "green" : "red",
							}}
						>
							Status: {statusCode}
						</Typography>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				{/* Request Section */}
				<Grid item xs={6}>
					<Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
						Request
					</Typography>
					<Box>
						<Tabs
							value={requestTab}
							onChange={(_, newValue) => setRequestTab(newValue)}
						>
							<Tab label="Headers" />
							<Tab label="Body" />
						</Tabs>
						<Box sx={{ mt: 2, border: "1px solid #333", borderRadius: 2 }}>
							{requestTab === 0 ? (
								<Editor
									height="400px"
									language="json"
									value={requestHeaders}
									onChange={(value) => setRequestHeaders(value || "{}")}
									theme="vs-dark"
								/>
							) : (
								<Editor
									height="400px"
									language={
										selectedApi?.content_type === "text/xml" ? "xml" : "json"
									}
									value={requestBody}
									onChange={(value) => setRequestBody(value || "{}")}
									theme="vs-dark"
								/>
							)}
						</Box>
					</Box>
				</Grid>

				{/* Response Section */}
				<Grid item xs={6}>
					<Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
						Response
					</Typography>
					<Box>
						<Tabs
							value={responseTab}
							onChange={(_, newValue) => setResponseTab(newValue)}
						>
							<Tab label="Headers" />
							<Tab label="Body" />
						</Tabs>
						<Box sx={{ mt: 2, border: "1px solid #333", borderRadius: 2 }}>
							{responseTab === 0 ? (
								<Editor
									height="400px"
									language="json"
									value={response.headers}
									options={{ readOnly: true }}
									theme="vs-dark"
								/>
							) : (
								<Editor
									height="400px"
									language="json"
									value={response.body}
									options={{ readOnly: true }}
									theme="vs-dark"
								/>
							)}
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default ApiTester;
