import Keycloak from 'keycloak-js';

const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
	_kc
		.init({
			onLoad: 'check-sso',
			silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
			pkceMethod: 'S256',
			enableLogging: true,
		})
		.then((authenticated) => {
			if (!authenticated) {
				console.log('user is not authenticated..!');
			}

			document.cookie = `jwt=${_kc.token};path=/;`;

			onAuthenticatedCallback();
		})
		.catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getRealmAccessRoles = () => {
	if (_kc.tokenParsed?.realm_access?.roles)
		return _kc.tokenParsed.realm_access.roles;
	else return [];
};

const getResourceAccessRoles = () => {
	if (_kc.tokenParsed?.resource_access?.account.roles)
		return _kc.tokenParsed.resource_access.account.roles;
	else return [];
};

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
	_kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getFirstName = () => _kc.tokenParsed?.given_name;
const getLastName = () => _kc.tokenParsed?.family_name;

const hasRole = (role) => _kc.hasRealmRole(role);

const getKC = () => _kc;

const UserService = {
	initKeycloak,
	doLogin,
	doLogout,
	isLoggedIn,
	getToken,
	updateToken,
	getUsername,
	getFirstName,
	getLastName,
	hasRole,
	getRealmAccessRoles,
	getResourceAccessRoles,
	getKC,
};

export default UserService;
