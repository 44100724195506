import { Box, Tabs, Tab, Alert, Snackbar } from "@mui/material";
import React, { useState, useContext } from "react";
import Header from "../Header/Header";
import { useUserContext } from "../../contexts/userContext";
import Setting from "./Setting";
import MassiveRetry from "./MassiveRetry";
import MassiveClear from "./MassiveClear";
import ApiTester from "./ApiTester";
import { useTheme } from "@emotion/react";
import { ColorModeContext, tokens } from "../../theme";
import OperativeState from "./OperativeState";

const Administration = () => {
	const [activeTab, setactiveTab] = useState("settings");
	const [tabValue, settabValue] = useState(0);
	const [snack, setsnack] = useState({
		open: false,
		message: "",
		status: "success",
	});

	const tabList = [
		{ label: "SETTINGS", disabled: false },
		{ label: "MASSIVE RETRY", disabled: false },
		{ label: "DAG MASSIVE CLEAR", disabled: false },
		{ label: "OPERATIVE STATE", disabled: false },
		{ label: "API TESTER", disabled: false },
	];

	const userContext = useUserContext();
	const user = userContext.getUser();
	const theme = useTheme();
	const themeMode = theme.palette.mode;
	const colors = tokens(themeMode);
	const colorMode = useContext(ColorModeContext);

	if (user && !user.hasRole("ngom-administrator")) window.location.href = "/";

	const handleTabs = (e, newValue) => {
		settabValue(newValue);
	};

	return user && !user.hasRole("ngom-administrator") ? (
		<Box></Box>
	) : (
		<Box sx={{ width: "100%", height: "90vh", padding: "20px" }}>
			<Snackbar
				open={snack.open}
				autoHideDuration={6000}
				onClose={() => setsnack((prev) => ({ ...prev, open: false }))}
			>
				<Alert severity={snack.status} variant="filled" sx={{ width: "100%" }}>
					{snack.message}
				</Alert>
			</Snackbar>
			<Header title="Administration" subtitle={"Administration page"} />
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Tabs
					value={tabValue}
					indicatorColor="secondary"
					variant="fullWidth"
					sx={{
						background: "#353535",
						width: "40%",
						".MuiTab-textColorPrimary": {
							color:
								themeMode === "light" ? colors.grey[900] : colors.grey[200],
						},
						".MuiTab-root": {
							background:
								themeMode === "light" ? colors.grey[500] : colors.grey[700],
						},
					}}
					onChange={handleTabs}
				>
					{tabList.map((tab, index) => (
						<Tab
							onClick={(e) => setactiveTab(tab.label.toLowerCase())}
							key={index}
							label={tab.label}
							disabled={tab.disabled ? tab.disabled : false}
							value={index}
						/>
					))}
				</Tabs>
			</Box>
			{activeTab === "settings" ? (
				<Setting setsnack={setsnack} />
			) : activeTab === "massive retry" ? (
				<MassiveRetry setsnack={setsnack} />
			) : activeTab === "dag massive clear" ? (
				<MassiveClear setsnack={setsnack} />
			) : activeTab === "operative state" ? (
				<OperativeState setsnack={setsnack} />
			) : (
				<ApiTester />
			)}
		</Box>
	);
};

export default Administration;
